import React, { useEffect } from "react";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div">
        <div className="intro">
          <div className="personal-care">Personal Care &amp; Fitness</div>
          <div className="text-wrapper">Website</div>
          <img className="image" alt="Image" src="/img/image.png" />
          <div className="group">
            <div className="context">
              <div className="text-wrapper-2">Web Design</div>
              <p className="p">
                Alivio is a fitness and personal care app that offers content
                on-demand from top-notch fitness instructors, therapists and
                coaches worldwide. We helped them design and build a brand new
                website for their mobile app considering the marketing
                objectives, business goals and users in mind while keeping the
                aesthetics clean minimal, relevant, and user-friendly.
              </p>
            </div>
            <div className="scope-of-work">
              <div className="overlap">
                <img className="UIX" alt="Uix" src="/img/uix.png" />
                <div className="SM">
                  <div className="overlap-group">
                    <div className="text-wrapper-3">Content</div>
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-2">
                        <img
                          className="line"
                          alt="Line"
                          src="/img/line-18.svg"
                        />
                        <img
                          className="img"
                          alt="Line"
                          src="/img/line-19.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-wrapper-4">Scope of Work</div>
              </div>
              <div className="WD">
                <div className="overlap-2">
                  <div className="text-wrapper-5">Web Development</div>
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-2">
                      <img
                        className="line"
                        alt="Line"
                        src="/img/line-18-1.svg"
                      />
                      <img
                        className="img"
                        alt="Line"
                        src="/img/line-19-1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="logo" alt="Logo" src="/img/logo.svg" />
        </div>
        <img className="website" alt="Website" src="/img/website.png" />
        <div className="outro">
          <div className="div-wrapper">
            <div className="logo-wrapper">
              <img className="logo-2" alt="Logo" src="/img/logo.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
